import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import TextField from '@mui/material/TextField';

import { ReactComponent as RedExclamationPointCircle } from '../images/icons/exclamation_point_red.svg';
import { ReactComponent as GreenCheckmark } from '../images/icons/green_checkmark.svg';

import { UserDataContext } from '../contexts';

import useFetch from '../hooks/useFetch';
import useAsync from '../hooks/useAsync';

import './CheckoutForm.scss';

export default function CheckoutForm({
  makePaymentButtonRef,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  clientSecret,
  discountPaymentData,
  setDiscountPaymentData,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { userData } = useContext(UserDataContext);

  const [cardHolderName, setCardHolderName] = useState('');

  const [message, setMessage] = useState('');

  // Discount
  const [codeMessage, setCodeMessage] = useState('');
  const [validCode, setValidCode] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const [discountCode, setDiscountCode] = useState('');

  const [, confirmStripePaymentRequest] = useAsync();

  const [{ loading: checkingDiscountCode }, discountCheckRequest] = useFetch();
  const [, applyFullDiscountRequest] = useFetch();


  const stripeInputStyle = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    color: '#49454F',
  };

  function handleSubmit() {
    if (!stripe || !elements) return;

    setPaymentIsProcessing(true);

    if (discountPaymentData.validCode && discountPaymentData.chargeAmount === '0') {
      applyFullDiscountRequest({
        url: '/stripe/apply-full-discount',
        body: discountPaymentData,
        onSuccess: () => setPaymentIsCompleted(true),
        onFinally: () => setPaymentIsProcessing(false),
      });
      return;
    }

    confirmStripePaymentRequest({
      promise: () => stripe.confirmCardPayment(
        elements._commonOptions.clientSecret.clientSecret,
        {
          payment_method: {
            type: 'card',
            card: elements?.getElement(CardNumberElement),
            billing_details: { name: cardHolderName },
          },
          receipt_email: userData.userAttributes.email,
        },
      ),
      onSuccess: (stripeResponse) => {
        if (!stripeResponse.error && stripeResponse.paymentIntent.status === 'succeeded') {
          setPaymentIsCompleted(true);
          setPaymentIsProcessing(false);
          return;
        }

        if (stripeResponse.error?.type === 'card_error' || stripeResponse.error?.type === 'validation_error') {
          setMessage(stripeResponse.error.message);
        } else {
          setMessage('An unexpected error occurred.');
        }
      },
      onFinally: () => setPaymentIsProcessing(false),
    });
  }

  function checkDiscountCode() {
    if (!discountCode) {
      setDiscountPaymentData({});
      setCodeError(false);
      setValidCode(false);
      setCodeMessage('');
      return;
    }
    const discountCodeData = { clientSecret, discountCode };
    discountCheckRequest({
      url: '/stripe/discount-check',
      body: discountCodeData,
      onSuccess: (response) => {
        if (response.Body.validCode) {
          setDiscountPaymentData({
            ...response.Body,
            ...discountCodeData,
          });
          setCodeMessage(response.Message);
          setCodeError(false);
          setValidCode(true);
        } else {
          setDiscountPaymentData({});
          setCodeError(true);
          setValidCode(false);
          setCodeMessage(response.Message);
        }
      },
    });
  }

  return (
    <form id="payment-form">
      <CardNumberElement
        className="card-number"
        options={{
          style: { base: stripeInputStyle },
          placeholder: 'Credit card number',
          disableLink: true,
        }}
      />
      <TextField
        type="text"
        className="name-input"
        placeholder="Name on card"
        onChange={(e) => setCardHolderName(e.target.value)}
      />
      <div>
        <CardExpiryElement
          className="exp-date"
          options={{
            style: { base: stripeInputStyle },
            placeholder: 'Expiration date',
          }}
        />
        <span className="helper-text">MM/YY</span>
      </div>
      <div>
        <CardCvcElement
          className="cvv"
          options={{
            style: { base: stripeInputStyle },
            placeholder: 'CVV',
          }}
        />
        <span className="helper-text">3 numbers on back of card, Amex: 4 on front</span>
      </div>
      <TextField
        className={`discountInput ${validCode ? 'codeValid' : ''}`}
        placeholder="Referral source code (optional)"
        value={discountCode}
        onChange={(e) => setDiscountCode(e.target.value)}
        onBlur={checkDiscountCode}
        autoComplete="off"
        helperText={checkingDiscountCode ? (
          <span className="dots-circle-spinner" />
        ) : codeError ? (
          <>
            <RedExclamationPointCircle className="red-exclamation-point-circle" />
            <span className="title-required">{codeMessage}</span>
          </>
        ) : validCode ? (
          <>
            <GreenCheckmark className="red-exclamation-point-circle" />
            <span className="title-required" style={{ color: 'green' }}>{codeMessage}</span>
          </>
        ) : ' '}
      />
      <button
        type="button"
        onClick={handleSubmit}
        aria-label="hidden-pay-button"
        ref={makePaymentButtonRef}
        style={{ display: 'none' }}
      />
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

CheckoutForm.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
  discountPaymentData: PropTypes.object.isRequired,
  setDiscountPaymentData: PropTypes.func.isRequired,
};
